@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import './utils/colours.scss';
@import './utils/text-styles.scss';
@import './utils/letter.scss';

@mixin dreamy-background() {
  background-image: url(./assets/dreamy_background.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;

  .content {
    display: flex;
    flex-direction: column;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    box-sizing: border-box;
    background: linear-gradient(90deg, $colour-background-green 0%, #617f63 50%, #b9ad85 100%); // Combine background-color and linear-gradient
    position: fixed !important;
    top: 0 !important;
    z-index: 1 !important;

    .title {
      font-size: 2.0rem;
      font-weight: 900;
      font-family: 'Roboto', sans-serif; // Specify font-family
      color: $colour-white;
    }
  }

  .footer {
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colour-white;
    background: linear-gradient(90deg, $colour-background-green-dark 0%, #55785e 50%, #3a4f4b 100%);
    bottom: 0;
    left: 0;
    z-index: 1;
    position: fixed;

    .link {
      text-decoration: none;
      padding: 0 21px; // Combine padding-left and padding-right
    }
  }

  .app {
    width: 100%;
    max-width: 1000px;
    padding: 12px;
    align-self: center;
    margin-top: 80px; // Added margin-top to ensure the app has sufficient space from the sticky header
    margin-bottom: 40px;

    &.quiz {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .quiz-bg {
        @include dreamy-background();
      }
    }

    .progress_container {
      width: 80%;
      margin: 0 auto;
      height: 25px;
      position: relative;

      .progress_text {
        position: absolute;
        top: -25px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 16px;
      }

      .progress {
        height: 100%;
        border-radius: 42px;
        background-color: $colour-background-green-light;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 300ms ease-in-out;
      }

      svg path {
        fill: $colour-text;
      }

      .prev,
      .next {
        background-color: transparent;
        cursor: pointer;
        outline: none;
        border: none;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 0px;

        svg {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .prev {
        left: -35px;
      }

      .next {
        right: -35px;
      }
    }

    .rolling {
      display: block;

      .screen {
        width: 100%;
        height: 50vh;
        position: relative;
        // overflow-x: hidden;
        padding: 0;

        .inner_scroll {
          width: 100%;
          height: 100%;
          position: relative;
          transition: all 300ms ease-in-out;

          .inner {
            width: 100%;
            height: 100%;
            padding: 40px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;

            select {
              display: block;
              width: 100%;
              padding: 10px;
              outline: none;
              border: none;
              background-color: $colour-white;
              border: 2px solid $colour-background-green-dark;
              border-radius: 6px;
              text-align: center;
              font-size: 22px;
              max-width: 400px;

              select:focus {
                outline: none;
              }

              option {
                text-align: center;
              }
            }


          }
        }
      }
    }

    .screen {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      &.background {
        // margin: 25px 0;
        padding: 40px 20px;
        border-radius: 42px;
        background-color: $colour-background-transparent;
      }


      &.dark {
        padding: 40px 20px;
        border-radius: 42px;
        background-color: $colour-background-transparent-dark;

        &.center {
          align-items: center;
          justify-content: center;
          // avoid scrolling
          overflow: hidden;
        }
      }

      &.veryDark {
        padding: 20px 10px;
        border-radius: 42px;
        background-color: $colour-background-transparent-darkest;
      }

      .heading {
        margin-bottom: 24px;
        color: $colour-text-dark;

        &.center {
          text-align: center;

          &.sub {
            font-size: 22px;
            margin-bottom: 12px;
          }
        }
      }

      &.graph_screen {
        background-image: none;

        .heading {
          text-align: center;
          color: $colour-text;
        }

        canvas {
          min-height: 300px;
        }
      }

      .graph {
        margin: 20px 20px;
      }

      .bar {
        margin: 40px 0;
      }
    }

    .action_btn {
      display: block;
      color: black;
      outline: none;
      border-radius: 12px;
      width: 250px;
      margin: 5px auto;
      font-size: 22px;
      color: $colour-text-dark;
      text-align: center;
      cursor: pointer;
      padding: 12px;
      border: none;
      background-color: $colour-background-green-light;
      size: 500%;
      animation: action_btn_anim infinite 20s linear;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); // Added shadow for modern look
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; // Added transition for smooth color and shadow changes
    }

    .action_btn.disabled {
      background-color: grey;
      cursor: not-allowed;
    }


    @keyframes action_btn_anim {
      0% {
        background-position: 100%;
      }

      100% {
        background-position: 0%;
      }
    }

    &.faq {
      color: $colour-text-dark;

      .faq-heading {
        margin-top: 20px;
        margin-bottom: 6px;
      }

      .faq-bg {
        @include dreamy-background();
      }
    }

    &.contact {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      color: $colour-text-dark;

      .contact-bg {
        @include dreamy-background();
      }
    }

    .contact-button {
      display: block;
      outline: none;
      border-radius: 12px;
      width: 250px;
      margin: 5px auto;
      font-size: 22px;
      color: $colour-text-dark;
      text-align: center;
      cursor: pointer;
      padding: 12px;
      border: none;
      background-color: $colour-background-green-light;
      size: 500%;
      animation: action_btn_anim infinite 20s linear;
    }

    &.compensate {
      .heading {
        margin-bottom: 30px;
        font-size: 28px;
      }

      .compensate-bg {
        @include dreamy-background();
      }
    }

    .donations {
      display: flex;
      align-items: center;
      justify-content: space-around;

      height: 300px;
      border-radius: 16px;
      flex-wrap: wrap;

      a {
        display: block;
        width: 50%;

        &.center {
          display: flex;
          align-items: center;
          justify-content: center;
        }


        img {
          display: block;
          width: 100%;
          max-height: 50px;
          // center image


          margin: 0 auto;
        }
      }
    }
  }

  &.impressum {
    .heading {
      margin-bottom: 30px;
      font-size: 28px;
      text-align: center;
    }

    .impressum-bg {
      @include dreamy-background();
    }
  }
}

.container_chart {
  width: 100%;
  max-width: 100%;
}

@media screen and (max-width: 600px) {
  .container {
    .app {
      &.compensate {
        .donations {
          a {
            width: 100%;
          }
        }
      }
    }
  }
}

.compareDropdowns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  .compareDropdown {
    width: 100%;
    max-width: 400px;
    margin: 10px;
  }

  select {
    width: 30%;
    padding: 2px;
    margin: 0 10px;
    outline: none;
    border: none;
    background-color: $colour-white;
    border: 1px solid $colour-background-green;
    border-radius: 6px;
    text-align: center;
    font-size: 22px;
    max-width: 400px;

    select:focus {
      outline: none;
    }

    option {
      text-align: center;
    }
  }
}

.rotating-img {
  animation: rotation 42s infinite linear;
  display: block;
  margin: 0 auto;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

img {
  max-width: 100%;
  height: auto;

  &.donation-img {
    // round corners of image
    border-radius: 12px;
  }
}

input[type="number"] {
  background-color: $colour-white;
  border-radius: 12px;
  // border
  border: 2px solid $colour-background-green-light;
  // size of input box
  width: 300px;
  height: 42px;
  // size of text
  font-size: 24px;
  padding: 5px;
  text-align: center;
  // font colour
  color: $colour-text;
  margin: 0 auto;
  display: block;
  box-sizing: border-box;
}

input[type="number"]:hover {
  background-color: $colour-background-green-light;
}

// remove border when input is selected
input[type="number"]:focus {
  outline: none;
}

// make the spin button super minimalistic
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// SEARCH BAR
// add country_search
.country_search {
  position: relative;
  margin: 0 auto;
  margin-bottom: 50px;
  width: 300px;
  padding: 0px;

}

.autocomplete_list {
  position: absolute;
  // round corners
  border-radius: 12px;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: $colour-white;
  border: 2px solid $colour-background-green-light;

  border-top: none;
  padding: 2px;
  margin: 0;
  list-style: none;
  z-index: 999;
}

.autocomplete_list li {
  padding: 10px;
  cursor: pointer;
}

.autocomplete_list li:hover {
  background-color: $colour-background-green-light;
}

input[type="search"] {
  padding: 8px;
  border-radius: 12px;
  border: 2px solid $colour-background-green-light;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

input[type="search"]:hover {
  background-color: $colour-background-green-light;
}

input[type="search"]:focus {
  outline: none;
}

input[type="search"]::-webkit-scrollbar {
  width: 2em;
  background-color: #F5F5F5;
}

input[type="search"]::-webkit-scrollbar-thumb {
  background-color: #000000;
}

// remove "x" button in search bar
input[type="search"]::-ms-clear {
  display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  cursor: pointer;
  padding: 8px;
}

li:hover {
  // round corners
  border-radius: 12px;
  background-color: $colour-background-green-light;
}

.suggestions {
  max-height: 200px;
  overflow-y: auto;
  // show max 5 suggestions
}

.income_level {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* changed from center to space-between to give space between buttons */
  width: 100%;
  gap: 10px;
  /* adds space between the buttons */

  .level {
    width: calc(100% / 3 - 10px);
    /* adjusting for the gap */
    background-color: white;
    /* neutral background */
    height: 48px;
    cursor: pointer;
    color: $colour-background-green-dark;
    /* dark text color */
    font-size: 20px;
    border: 1px solid $colour-background-green-light;
    /* Replace with your light green color variable or hex code */
    border-radius: 4px;
    /* Slightly rounded corners */
    outline: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #E0E0E0;
      /* subtle hover effect */
    }

    &.selected {
      background-color: $colour-background-green-light;
      color: black;
      border: 1px solid $colour-background-green-dark;
      /* border color to match background */
    }
  }
}

.no-wrap {
  white-space: nowrap;
  overflow-x: auto;
}

/* Style for placeholder text */
::placeholder {
  color: lightgrey;
  font-style: italic;
  /* Optional: italicize the placeholder text */
  font-size: 24px;
  /* Added font size as per instructions */
  text-align: center;
}

/* For better browser compatibility, include the following vendor prefixes */
::-webkit-input-placeholder {
  /* Chrome, Edge, Safari */
  color: lightgrey;
  font-style: italic;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: lightgrey;
  font-style: italic;
  opacity: 1;
  /* Firefox adds an extra layer of opacity to the placeholder, so you might want to override that */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: lightgrey;
  font-style: italic;
}

:-moz-placeholder {
  /* Mozilla Firefox 4-18 */
  color: lightgrey;
  font-style: italic;
  opacity: 1;
}

.invisible_button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}