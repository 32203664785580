/* Reset Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Global Styles */
  body {
    font-family: "Roboto";
    background-color: $colour-white;
  }
  
  a {
    text-decoration: none;
    color: $colour-background-green-light;
    text-decoration: underline;
  }
  
  .center {
    align-items: center;
    justify-content: center;
  }
  
  /* Typography Styles */
  p {
    font-size: 1.4rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: $colour-text;
  }
  
  p.center {
    text-align: center;
  }
  
  p.center.sub {
    font-size: 22px;
    margin-bottom: 12px;
  }
  
  .heading {
    color: $colour-text-dark;
    align-items: center;
    justify-content: center;
  }
  
  .heading.center {
    text-align: center;
  }
  
  h1 {
    font-size: 2.4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;
  }
  
  h1 .mark {
    display: inline-block;
    line-height: 0em;
    padding-bottom: 0.2em;
  }
  
  h2 {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  h3 {
    font-size: 1.6rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  
  /* Highlight Styles */
  .highlight {
    background: linear-gradient(
      178deg,
      $colour-highlight-full-transparency 42%,
      $colour-highlight-green 69%
    );
    white-space: pre-wrap;
}
  
  .highlight.negative {
    background: linear-gradient(
      178deg,
      $colour-highlight-full-transparency 42%,
      $colour-highlight-red 69%
    );
  }
  