/*****************************************/
/*           Highlight colours           */
/*****************************************/

// text colours 
$colour-text: #264653;
$colour-text-dark: #314026;

// background colours
$colour-background-green: #8fa276;
$colour-background-green-dark: #71906f;
$colour-background-green-light: #d2fec2;
// transparent background colours
$colour-background-transparent: #ffffff6c;
$colour-background-transparent-dark: #ffffffa1;
$colour-background-transparent-darkest: #ffffffe1;

// highlight colours
$colour-highlight-full-transparency: #ffffff00;
$colour-highlight-green: #d2fec2cc;
$colour-highlight-red: #f5cfcd;

// chart colours
$colour-chart-user: #f3938f;
$colour-chart-representative-person: #C8FFD4;
$colour-chart-poor-person-same-country: #B8E8FC;

// basic colors
$colour-white: #ffffff;
$colour-black: #000000;